import Layout from "./Layout";
import LanguageWelcomeMessagesInitializor from "./LanguageWelcomeMessagesInitializor";
import LanguageSpecificWelcomeMessage from "./LanguageSpecificWelcomeMessage";

export default class HomePageLayoutData {
  theme = "";
  leftLogo = "";
  rightLogo = "";
  modules = new Array<Layout>();
  welcomeMessages =
    LanguageWelcomeMessagesInitializor.getDefaultLanguageWelcomeMessages();

  constructor(
    theme?: string,
    leftLogo?: string,
    rightLogo?: string,
    modules?: Layout[],
    welcomeMessages?: LanguageSpecificWelcomeMessage[]
  ) {
    this.theme = theme ? theme : "";
    this.leftLogo = leftLogo ? leftLogo : "";
    this.rightLogo = rightLogo ? rightLogo : "";
    this.modules = modules ? modules : new Array<Layout>();
    this.welcomeMessages = welcomeMessages
      ? welcomeMessages
      : LanguageWelcomeMessagesInitializor.getDefaultLanguageWelcomeMessages();
  }
}
