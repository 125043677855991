import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ApiService from "@/services/ApiService";

// plugins
import vueGridLayout from "./plugins/vue-grid-layout";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import VueCookies from "vue-cookies";

// For IE 10 Support
import "core-js/stable";
import "regenerator-runtime/runtime";

import Keycloak from "keycloak-js";
import { getModule } from "vuex-module-decorators";
import ExternalConfigModule from "@/store/modules/ExternalConfigModule";

declare module "vue/types/vue" {
  interface VueConstructor {
    $keycloak: any;
    
  }
}

Vue.config.productionTip = false;
Vue.use(vueGridLayout);
Vue.use(VueCookies);

const module = getModule(ExternalConfigModule, store);

module.getExternalConfigAction().finally(() => {
  // Init Keycloak and mount app when promise is finally either fulfilled or rejected
  // In case that getExternalConfigAction throw an error(maybe if file dosen't exist) fallback config from env files will be used

  const options = {
    url: module.externalConfig.KEYCLOAK_URL,
    realm: module.externalConfig.KEYCLOAK_REALM,
    clientId: module.externalConfig.KEYCLOAK_CLIENT_ID,
    onLoad: "login-required",
  };

  const _keycloak = Keycloak(options);
  Vue.$keycloak = _keycloak;

  Vue.$keycloak.init({ checkLoginIframe: false }).then(() => {
    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
  });
});

// API service init
ApiService.init();
